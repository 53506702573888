import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PensionAnnuityView from '../views/PensionAnnuityView.vue'
import AnnuityView from '../views/AnnuityView.vue'
import CommonErrorView from '../views/CommonErrorView.vue'
import WechatLoginView from '../views/WechatLoginView.vue'
Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'home',
		component: HomeView
	}, {
		path: '/pension_annuity',
		name: 'pension_annuity',
		component: PensionAnnuityView
	}, {
		path: '/annuity',
		name: 'annuity',
		component: AnnuityView
	}, {
		path: '/error',
		name: 'error',
		component: CommonErrorView
	}, {
		path: '/wechat_login',
		name: 'wechat_login',
		component: WechatLoginView
	}
]

const router = new VueRouter({
	routes
})

export default router
