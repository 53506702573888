<template>
    <div>
        <van-empty style="text-align: center;" image="error" :description="msg" />
    </div>
</template>
<script>
export default {
    name:"commonError",
    data(){
        return {
            msg:"客户端发生未知错误",
        }
    },
    created(){
        if(this.$route.params.msg){
            this.msg=this.$route.params.msg
        }        
    }
}
</script>