import Vue from 'vue'
import App from './App.vue'
import router from './router'

import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

import "amfe-flexible";

import Vconsole from 'vconsole'
let vConsole = new Vconsole()
Vue.use(vConsole);

import common from "@/tools/common"
// 登录
await common.wxLogin()

Vue.config.productionTip = false

new Vue({
	router,
	render: h => h(App)
}).$mount('#app')
