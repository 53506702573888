<template>
    <div>
        <customTabs currentName="annuity"></customTabs>
        <van-empty description="描述文字" />
    </div>
</template>
<script>
import customTabs from '../components/customTabs/customTabs'
export default {
		name: "AnnuityView",
        components:{
			customTabs
		},
		data() {
            return {

            }
        },
        methods:{

        }
}
</script>