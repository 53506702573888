import axios from 'axios'
import { Toast } from 'vant';
import router from '@/router'
import { signatureGenerate } from './signatureUtil.js'
import common from "@/tools/common";
// 添加请求拦截器
axios.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    var token = localStorage.getItem("token")
    if(!token || token==undefined || token=='undefined' || token ==null || token=='null'){
        token=''
    }
    config.headers['Authorization'] = "Bearer " + token;
    config.headers['Content-Type'] = 'application/json';
    // 获取请求头参数
    const { signature, timestamp, secret } = signatureGenerate(config)
    // 分别将签名、密钥、时间戳 至请求头 
    if (signature) config.headers["signature"] = signature
    // if (secret) config.headers["key"] = secret
    // if (timestamp) config.headers["timestamp"] = timestamp

    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    return response.data
}, async function (error) {
    Toast.clear()
    console.log(error)
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么

    if (error.response.status === 401) {
        Toast.fail(error.response.data.msg)
        localStorage.removeItem("token")
        await common.wxLogin(true);
        // 接口的根地址截取下来，判断
        var flag = router.app.$route.path
        // 判断地址是哪一个页面的，push到相应的错误页面
        // router.push("/wechat_login")
        // if (flag != '/wechat_login') {
        //     router.push("/wechat_login")
        // }
    }else if(error.response.status!=200){
        localStorage.removeItem("token")
        router.push({
            name:"error",
            params:{
                msg:error.response.data.msg,
            }
        })
        
    }
    return Promise.reject(error);
});

var ajax = {
    // get请求
    get(url, params) {
        return new Promise((resolve, reject) => {
            axios({
                methods: "get",
                url,
                params,
            }).then(res => {
                resolve(res.data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    //post请求
    post(url, params) {
        return new Promise((resolve, reject) => {
            axios.post(
                url,
                params,
            ).then(res => {
                resolve(res.data)
            }).catch(error => {
                reject(error)
            })
        })
    },
}
export default ajax