<template>
	<div class="container">
		<customTabs currentName="increased"></customTabs>
		<!-- 保司 -->
		<van-action-sheet v-model="productDialog" round title="产品列表">
			<div>
				<van-cascader active-color="#1989FA" v-model="cascaderValue" @finish="onFinish" :closeable="false" :options="productData" :field-names="{ text: 'text', value: 'cid', children: 'children' }">
					<template #title>
						<van-search v-model="productCondition.keyword" @search="getProductList" placeholder="请输入搜索关键词" />
					</template>
				</van-cascader>
			</div>
		</van-action-sheet>
		<!-- 产品条件 -->
		<van-action-sheet v-model="conditionDialog" title="条件">
			<div>
				<van-field name="stepper" label="年龄">
					<template #input>
						<van-stepper min="0" v-model="tempCondition.age" />
					</template>
				</van-field>
				<van-field name="radio" label="性别">
					<template #input>
						<van-radio-group v-model="tempCondition.sex" direction="horizontal">
							<van-radio name="0">男</van-radio>
							<van-radio name="1">女</van-radio>
						</van-radio-group>
					</template>
				</van-field>
				<van-field :value="getYearText()" label="缴费年限" is-link readonly @click="showYearPicker = true" name="picker" placeholder="请选择缴费年限"></van-field>
				<van-field name="stepper" label="缴费额度">
					<template #input>
						<van-stepper step="1000" v-model="tempCondition.base_money" integer />
					</template>
				</van-field>
				<van-field :value="getOpText()" v-if="opList.length>0" label="可选计划" is-link readonly @click="showOpPicker = true" name="picker" placeholder="请选择缴费年限"></van-field>
				<div class="confirm">
					<van-button round block type="info" native-type="submit" @click="confirmProduct">
						确定
					</van-button>
				</div>
			</div>
		</van-action-sheet>
		<!-- 缴费年限 -->
		<van-popup v-model="showYearPicker" position="bottom">
			<van-picker title="缴费年限" show-toolbar :columns="yearData" @confirm="confirmYear" @cancel="showYearPicker = false" />
		</van-popup>
		<!-- 可选计划 -->
		<van-popup v-model="showOpPicker" position="bottom">
			<van-picker title="可选计划" show-toolbar :columns="opList" @confirm="confirmOp" @cancel="showOpPicker = false" />
		</van-popup>
		<!-- 展示设置 -->
		<div style="margin: 10px 0px;">
			<van-cell-group>
				<van-cell title="展示设置" is-link @click="showSetDialog=true">
					<template #label>
						<span>现价年度间隔{{shareSeting.show_cv_gap}}年、</span>
						<span>{{shareSeting.show_age==1 ? '显示' :'隐藏'}}年龄、</span>
						<span>{{shareSeting.show_feng_bi_qi==1 ? '显示' : '隐藏'}}封闭期、</span>
						<span>{{shareSeting.show_jian_bao_info==1 ? '显示' :'隐藏'}}减保信息、</span>
						<span>IRR/单利/年度间隔{{shareSeting.irr_rate_gap}}年、</span>
						<span>{{shareSeting.show_irr==1 ? '显示' : '隐藏'}}IRR、</span>
						<span>{{shareSeting.show_rate==1 ? '显示' : '隐藏'}}单利、</span>
						<span>{{shareSeting.number_format==1 ? '纯数字' : '带千分符'}}</span>
					</template>
				</van-cell>
			</van-cell-group>
		</div>
		<!-- 展示设置详细选项 -->
		<van-action-sheet v-model="showSetDialog" round title="展示设置修改">
			<van-field name="show_cv_gap" label="现价年度间隔">
				<template #input>
					<van-radio name="1" v-model="shareSeting.show_cv_gap">1年</van-radio>
					<van-radio name="5" v-model="shareSeting.show_cv_gap">5年</van-radio>
					<van-radio name="10" v-model="shareSeting.show_cv_gap">10年</van-radio>
				</template>
			</van-field>
			<van-field name="show_age" label="显示年龄">
				<template #input>
					<van-switch active-value="1" inactive-value="0" size="25" v-model="shareSeting.show_age" />
				</template>
			</van-field>
			<van-field name="show_feng_bi_qi" label="显示“封闭期”">
				<template #input>
					<van-switch active-value="1" inactive-value="0" size="25" v-model="shareSeting.show_feng_bi_qi" />
				</template>
			</van-field>
			<van-field name="show_jian_bao_info" label="显示减保信息">
				<template #input>
					<van-switch active-value="1" inactive-value="0" size="25" v-model="shareSeting.show_jian_bao_info" />
				</template>
			</van-field>
			<van-field name="irr_rate_gap" label="IRR/单利/年度间隔">
				<template #input>
					<van-radio name="1" v-model="shareSeting.irr_rate_gap">1年</van-radio>
					<van-radio name="5" v-model="shareSeting.irr_rate_gap">5年</van-radio>
					<van-radio name="10" v-model="shareSeting.irr_rate_gap">10年</van-radio>
				</template>
			</van-field>
			<van-field name="show_irr" label="显示IRR">
				<template #input>
					<van-switch active-value="1" inactive-value="0" size="25" v-model="shareSeting.show_irr" />
				</template>
			</van-field>
			<van-field name="show_rate" label="显示单利">
				<template #input>
					<van-switch active-value="1" inactive-value="0" size="25" v-model="shareSeting.show_rate" />
				</template>
			</van-field>
			<van-field name="show_product_title" label="隐藏险种名">
				<template #input>
					<van-checkbox-group v-model="shareSeting.show_product_title" direction="horizontal">
						<van-checkbox shape="square" size="25" name="1">险种1</van-checkbox>
						<van-checkbox shape="square" size="25" name="2">险种2</van-checkbox>
						<van-checkbox shape="square" size="25" name="3">险种3</van-checkbox>
					</van-checkbox-group>
				</template>
			</van-field>
			<van-field name="number_format" label="数字格式">
				<template #input>
					<van-radio name="1" v-model="shareSeting.number_format">纯数字</van-radio>
					<van-radio name="2" v-model="shareSeting.number_format">带千分符</van-radio>
				</template>
			</van-field>
		</van-action-sheet>
		<!-- 选择产品板块 -->
		<van-sticky>
			<div class="product-select">
				<div class="row">
					<div class="value year">保单<br />年度</div>
					<div class="value" v-for="index of 3" :key="index">
						<div v-if="checkSelected(index-1)" @click="openProduct(index)">
							<van-icon name="plus" color="#fff" size="40">
							</van-icon>
							<div>险种{{index}}</div>
						</div>
						<div v-else @click="editProductWhere(index-1)">
							<span v-if="shareSeting.show_product_title.some(ex=>ex==index)">险种{{index}}</span>
							<span v-else>{{conditionProduct[index-1].product}}</span>
						</div>
						<van-icon @click.stop="delCondition(index-1)" v-if="checkSelected(index-1)==false" class="close" name="close" color="red" size="20"></van-icon>
					</div>
				</div>
			</div>
		</van-sticky>

		<template v-if="diffDataListNum()">
			<!-- 产品条件 -->
			<div class="condition">
				<div class="row">
					<div class="value year">条件</div>
					<div class="value" v-for="index of 3" :key="index">
						<template v-if="!confirmWhere[index-1]">
							--
						</template>
						<template v-else>
							<div class="label">
								<van-tag class="tag" plain type="primary">{{confirmWhere[index-1].age}}岁</van-tag>
							</div>
							<div class="label">
								<van-tag class="tag" plain type="success">{{confirmWhere[index-1].sex == 1 ? '女' : '男'}}</van-tag>
							</div>
							<div class="label">
								<van-tag class="tag" plain type="default">{{confirmWhere[index-1].year_limit==1 ? '趸交' : confirmWhere[index-1].year_limit}}{{confirmWhere[index-1].year_limit != 1 ? '年' : ''}}</van-tag>
							</div>
							<div class="label">
								<van-tag class="tag" plain type="warning">{{confirmWhere[index-1].base_money}}元</van-tag>
							</div>
						</template>

					</div>
				</div>
			</div>
			<!-- 减保 -->
			<div class="condition" v-if="shareSeting.show_jian_bao_info==1">
				<div class="row">
					<div class="value year">减保</div>
					<div class="value">
						<van-button block size="small" type="primary" text="减保"></van-button>
					</div>
					<div class="value">
						<van-button block size="small" type="warning" text="减保"></van-button>
					</div>
					<div class="value">
						<van-button block size="small" type="info" text="减保"></van-button>
					</div>
				</div>
			</div>
			<!-- 年保费 -->
			<div class="condition">
				<div class="row">
					<div class="value year">年保费</div>
					<template v-for="index of 3">
						<div class="value" :key="index" v-if="confirmWhere[index-1]">{{confirmWhere[index-1].base_money}}</div>
						<div class="value" :key="index" v-else>--</div>
					</template>
				</div>
			</div>
			<!-- 总保费 -->
			<div class="condition">
				<div class="row">
					<div class="value year">总保费</div>
					<template v-for="index of 3">
						<div class="value" :key="index" v-if="confirmWhere[index-1]">{{confirmWhere[index-1].base_money*confirmWhere[index-1].year_limit}}</div>
						<div class="value" :key="index" v-else>--</div>
					</template>
				</div>
			</div>
			<!-- 数据对比板块 -->
			<div class="diff-list">
				<div class="diff-title">
					现金价值
				</div>
				<template v-for="year of loopMaxNum">
					<div class="row" :key="'cv-'+year" v-if="year%shareSeting.show_cv_gap==0">
						<div class="value year">{{ year }}年</div>
						<div class="value" v-for="index of 3" :key="index">
							<template v-if="diffDataList[index-1] && diffDataList[index-1][year-1]">
								<span :style="diffDataList[index-1][year-1].cv_style">
									{{ toFormat(diffDataList[index-1][year-1].cv) }}
								</span>
								<template v-if="diffDataList[index-1][year-1].multiple>0">
									<van-tag type="success">X{{diffDataList[index-1][year-1].multiple}}</van-tag>
								</template>
								<template v-else-if="diffDataList[index-1][year-1].age>0 && shareSeting.show_age==1">
									<van-tag type="primary">{{diffDataList[index-1][year-1].age}}岁</van-tag>
								</template>
							</template>
							<template v-else>
								--
							</template>
						</div>
					</div>
				</template>
				<template v-if="shareSeting.show_irr==1">
					<div class="diff-title">
						IRR
					</div>
					<template v-for="year of loopMaxNum">
						<div class="row" :key="'irr-'+year" v-if="year%shareSeting.irr_rate_gap==0">
							<div class="value year">{{ year }}年</div>
							<div class="value" v-for="index of 3" :key="index">
								<template v-if="diffDataList[index-1] && diffDataList[index-1][year-1]">
									<span :style="diffDataList[index-1][year-1].irr_style">
										{{ diffDataList[index-1][year-1].irr >0 ? diffDataList[index-1][year-1].irr+'%' : '--' }}
									</span>
								</template>
								<template v-else>
									--
								</template>
							</div>
						</div>
					</template>
				</template>

				<template v-if="shareSeting.show_rate==1">
					<div class="diff-title">
						单利
					</div>
					<template v-for="year of loopMaxNum">
						<div class="row" :key="'rate-'+year" v-if="year%shareSeting.irr_rate_gap==0">
							<div class="value year">{{ year }}年</div>
							<div class="value" v-for="index of 3" :key="index">
								<template v-if="diffDataList[index-1] && diffDataList[index-1][year-1]">
									<span :style="diffDataList[index-1][year-1].rate_style">
										{{ diffDataList[index-1][year-1].rate > 0 ? diffDataList[index-1][year-1].rate+'%' : '--' }}
									</span>
								</template>
								<template v-else>
									--
								</template>
							</div>
						</div>
					</template>
				</template>
				<div id="cv-echart" style="height:400px;width: 100%;">

				</div>
			</div>
		</template>
		<template v-else>
			<!-- 对比记录 -->
			<div class="diff-logs">
				<div class="title">
					<van-icon name="clock-o" />
					<span>对比记录</span>
				</div>
				<div v-for="item of 5" :key="item" class="diff-row">
					<span>百年盛世鑫升终身寿险 </span>
					<span class="vs">vs</span>
					<span>财信人寿臻爱传家21终身寿险 </span>
					<span class="vs">vs</span>
					<span>财信人寿臻爱传家21终身寿险</span>
				</div>
			</div>
		</template>
	</div>
</template>


<script>
	import customTabs from '../components/customTabs/customTabs'
	import { getProduct, getIncreasedLifespan } from "@/tools/api"
	import { numFormat } from "@/tools/common"
	import * as echarts from 'echarts';
	export default {
		name: "HomeView",
		components: {
			customTabs
		},
		data() {
			return {
				productDialog: false,
				showSetDialog: false,
				productData: [],

				product_dialog: true,
				conditionDialog: false,
				showYearPicker: false,
				showOpPicker: false,
				ageActions: [
					{
						val: 0,
						name: "男",
					},
					{
						val: 1,
						name: "女",
					},
				],
				conditionProduct: [
					{}, {}, {}
				],
				global_index: -1,
				tempCondition: {
					cid: "",
					age: "30",
					sex: "0",
					year_limit: "1",
					base_money: 10000,
					op: "",
				},
				yearData: [],
				opList: [],
				confirmWhere: [],
				diffDataList: [],
				loopMaxNum: 0,
				cascaderValue: "",
				productCondition: {
					type: 1,
					keyword: "",
				},
				shareSeting: {
					// 现价年度价格
					show_cv_gap: "1",
					// 显示年龄
					show_age: "1",
					// 显示封闭期
					show_feng_bi_qi: "0",
					// 显示减保信息
					show_jian_bao_info: "1",
					// irr、单利、年度间隔
					irr_rate_gap: "1",
					// 显示irr
					show_irr: "1",
					// 显示单利,
					show_rate: "1",
					// 显示险种名
					show_product_title: [],
					// 数字格式
					number_format: "1",
				},
			};
		},
		computed: {

		},
		watch: {
			diffDataList() {
				var loopMaxNum = 0
				this.diffDataList.forEach(function (item) {
					if (item && loopMaxNum < item.length) {
						loopMaxNum = item.length
					}
				})
				this.loopMaxNum = loopMaxNum
				for (let i = 0; i < loopMaxNum; i++) {
					var cv_arr = []
					var irr_arr = []
					var rate_arr = []
					this.diffDataList.forEach(list => {
						if (list && list[i]) {
							list[i].cv_style = {}
							list[i].irr_style = {}
							list[i].rate_style = {}

							cv_arr.push(parseFloat(list[i].cv))
							irr_arr.push(parseFloat(list[i].irr) > 0 ? parseFloat(list[i].irr) : 0)
							rate_arr.push(parseFloat(list[i].rate) > 0 ? parseFloat(list[i].rate) : 0)
						} else {
							cv_arr.push(0)
							irr_arr.push(0)
							rate_arr.push(0)
						}
					});
					var val, index
					// 现价标红
					val = Math.max.apply(null, cv_arr);
					index = cv_arr.findIndex(row => row == val)
					if (this.diffDataList[index]) {
						if (index == -1) {
							this.diffDataList[index][i].cv_style = {}
						} else {
							this.diffDataList[index][i].cv_style = { color: 'red' }
						}
					}

					// irr标红
					val = Math.max.apply(null, irr_arr);
					index = irr_arr.findIndex(row => row == val)
					if (this.diffDataList[index]) {
						if (index == -1) {
							this.diffDataList[index][i].irr_style = {}
						} else {
							this.diffDataList[index][i].irr_style = { color: 'red' }
						}
					}

					// 单利标红
					val = Math.max.apply(null, rate_arr);
					index = rate_arr.findIndex(row => row == val)
					if (this.diffDataList[index]) {
						if (index == -1) {
							this.diffDataList[index][i].rate_style = {}
						} else {
							this.diffDataList[index][i].rate_style = { color: 'red' }
						}
					}

				}
			}
		},
		methods: {
			getProductList() {
				this.$toast.loading({
					message: '加载中...',
					forbidClick: true,
				});
				getProduct(this.productCondition).then(data => {
					// TODO
					this.confirmWhere = JSON.parse('[{"cid":"999cf107-928c-4dc8-9d4d-a37df219489b","age":"30","sex":"0","year_limit":"1","base_money":10000,"op":""}]')
					this.confirmWhere.forEach((item, idx) => {
						var index = data.findIndex(row => row.cid == item.cid)
						if (index != -1) {
							console.log(data[index])
							this.conditionProduct.splice(idx, 1, data[index])
							console.log(this.conditionProduct)
						}
					});
					this.global_index = 0
					this.getDiffData()

					this.$toast.clear()
					// 格式化产品数据
					var productData = []
					data.forEach(item => {
						var index = productData.findIndex(row => row.com == item.com)
						if (index == -1) {
							productData.push({
								cid: item.zimu + "-" + item.com,
								text: item.com,
								com: item.com,
								children: []
							})
							index = productData.length - 1
						}
						productData[index].children.push(item)
					});
					this.productData = productData

				}).catch(err=>{
					console.log(err)
				})
			},
			onFinish({ selectedOptions }) {
				this.changeIds(selectedOptions[1])
			},
			changeIds(item) {
				if (this.global_index == -1) {
					this.$toast("error")
				}
				this.conditionProduct[this.global_index] = item
				this.yearData = item.year_list
				this.opList = []
				item.oplist.forEach(item => {
					if (item[1] != 'None') {
						this.opList.push({
							text: item[0],
							val: item[1]
						})
					}
				});
				this.tempCondition = {
					cid: item.cid,
					age: "30",
					sex: "0",
					year_limit: "1",
					base_money: 10000,
					op: "",
				}
				if (this.opList.length > 0) {
					this.tempCondition.op = parseInt(this.opList[0].val)
				}
				var newConfirmWhere = this.confirmWhere.filter(item => {
					if (item) {
						return item
					}
				})
				if (newConfirmWhere.length > 0) {
					this.tempCondition = {
						cid: item.cid,
						age: newConfirmWhere[0].age,
						sex: newConfirmWhere[0].sex,
						year_limit: newConfirmWhere[0].year_limit,
						base_money: newConfirmWhere[0].base_money,
						op: ""
					}
				}
				this.conditionDialog = true
			},
			openProduct(index) {
				this.global_index = index - 1
				this.productDialog = true
			},
			checkSelected(index) {
				if (this.confirmWhere.length <= 0) {
					return true
				}
				if (!this.confirmWhere[index]) {
					return true
				}
				return false
			},
			delCondition(index) {
				this.global_index = -1
				this.$set(this.conditionProduct, index, {})
				// this.confirmWhere.splice(index,1)
				// this.confirmWhere[index] = null
				this.confirmWhere.splice(index, 1, null)
				// this.diffDataList[index] = null
				this.diffDataList.splice(index, 1, null)
			},
			confirmYear(val) {
				this.tempCondition.year_limit = val
				this.showYearPicker = false
			},
			confirmOp(data) {
				this.tempCondition.op = data.val
				this.showOpPicker = false
			},
			confirmProduct() {
				this.confirmWhere[this.global_index] = this.tempCondition
				this.conditionDialog = false
				this.productDialog = false
				this.cascaderValue = ""
				this.getDiffData()
			},
			editProductWhere(index) {
				this.global_index = index
				this.tempCondition = {
					cid: this.confirmWhere[index].cid,
					age: this.confirmWhere[index].age,
					sex: this.confirmWhere[index].sex,
					year_limit: this.confirmWhere[index].year_limit,
					base_money: this.confirmWhere[index].base_money,
					op: this.confirmWhere[index].op,
				}
				this.conditionDialog = true
			},
			getYearText() {
				return this.tempCondition.year_limit + "年"
			},
			getOpText() {
				if (!this.conditionProduct[this.global_index]) {
					return ''
				}
				var index = this.conditionProduct[this.global_index].oplist.findIndex(item => item[1] == this.tempCondition.op)
				if (index == -1) {
					return ''
				}
				return this.conditionProduct[this.global_index].oplist[index][0]
			},
			diffDataListNum() {
				var newConfirmWhere = this.confirmWhere.filter(item => {
					if (item) {
						return item
					}
				})
				return newConfirmWhere.length > 0
			},
			getDiffData() {
				var that = this
				// console.log(JSON.stringify(this.confirmWhere))
				var form = this.confirmWhere[this.global_index]
				this.$toast.loading({
					message: '加载中...',
					forbidClick: true,
				});
				getIncreasedLifespan(form).then(data => {
					this.$toast.clear()
					var totalPremium = form.base_money * form.year_limit
					var multiple = 0
					var age = parseInt(form.age)
					data.forEach(item => {
						// 倍数
						var multipleTemp = parseInt(item.cv / totalPremium)
						if (multipleTemp > multiple) {
							multiple = multipleTemp
							item.multiple = multiple
						} else {
							item.multiple = 0
						}
						// 年龄
						age += 1
						if (age % 10 == 0) {
							item.age = age
						} else {
							item.age = 0
						}
					});
					that.$set(that.diffDataList, that.global_index, data)
				}).catch(err=>{
					console.log(err)
				})
			},
			toFormat(value) {
				if (this.shareSeting.number_format == 2) {
					return numFormat(value)
				}
				return value

			}
		},
		created() {
			this.getProductList()
			this.$nextTick(function () {
				
			})

		}
	};
</script>

<style lang="scss">
	page {
		height: 100%;
	}

	.container {
		height: 100%;
		/* padding: 10px; */
		overflow-y: auto;
		background: #f8f8f8;
	}

	.product-select {
		background: #fff;

		.row {
			height: 80px;
			/* line-height: 40px; */

			.value {
				position: relative;

				div {
					display: flex;
					flex-direction: column-reverse;
				}

				.close {
					position: absolute;
					right: 5px;
					bottom: 5px;
				}
			}

			.value:nth-child(2) {
				background: #07C160;
				color: #fff;
			}

			.value:nth-child(3) {
				background: #FF976A;
				color: #fff;
			}

			.value:nth-child(4) {
				background: #1989FA;
				color: #fff;
			}
		}
	}

	.condition,
	.product-select,
	.diff-list {
		.diff-title {
			padding: 10px 0px;
			font-size: 16px;
			text-align: center;
			margin: 10px 0px;
			background: #fff;
		}

		.row {
			display: flex;
			margin-bottom: 2px;

			.value {
				background: #fff;
				margin-right: 4px;
				flex: 1;
				padding: 6px;
				text-align: center;
				text-overflow: ellipsis;
				overflow: hidden;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.year {
				max-width: 15%;
				background: #eee;
			}
		}
	}

	.condition {
		.value {
			display: flex;
			flex-wrap: wrap;

			.label {
				margin-right: 2px;
				margin-bottom: 2px;
			}
		}
	}

	.container {

		.item {
			display: flex;
			align-items: center;
			margin: 5px 0px;
			padding: 5px;
			border-bottom: 1px solid #eee;
			text-align: center;

			.letter {
				width: 25px;
			}

			.product-name {
				width: 100%;
				text-align: left;
			}

			.active-status {
				width: 25px;
			}
		}
	}

	.tag {
		padding: 1px 2px
	}

	.diff-logs {
		padding: 5px;

		.title {
			font-weight: 600;
			background: #fff;
			padding: 10px;
			border-radius: 10px;
		}

		.diff-row {
			margin: 10px 0px;
			background: #fff;
			border-radius: 10px;
			padding: 10px;
			color: #576B95;
			cursor: pointer;

			.vs {
				font-weight: 600;
				color: red;
				font-size: 16px;
			}
		}

		.diff-row:active {
			background: #eee;
		}
	}

	.confirm {
		padding: 10px;
	}
</style>