import { wxLogin } from "@/tools/api"
import { Toast } from "vant";
var common = {
    APPID:"wx0e568c127f62c058",
    numFormat(num) {
        num = num.toString().split(".");  // 分隔小数点
        var arr = num[0].split("").reverse();  // 转换成字符数组并且倒序排列
        var res = [];
        for (var i = 0, len = arr.length; i < len; i++) {
            if (i % 3 === 0 && i !== 0) {
                res.push(",");   // 添加分隔符
            }
            res.push(arr[i]);
        }
        res.reverse(); // 再次倒序成为正确的顺序
        if (num[1]) {  // 如果有小数的话添加小数部分
            res = res.join("").concat("." + num[1]);
        } else {
            res = res.join("");
        }
        return res;
    },
    getParams(name, url) {
        url = decodeURIComponent(location.search); //获取url中"?"符后的字串
        var strs
        var theRequest = new Object();
        if (url.indexOf("?") != -1) {
            var str = url.substr(1);
            strs = str.split("&");
            for (var i = 0; i < strs.length; i++) {
                theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
            }
        }
        return theRequest;
    },
    async wxLogin(isForce=false) {
        if (!localStorage.getItem("token")) {
            var data = this.getParams()
            if (!data || !data.code || isForce) {
                var appid = this.APPID
                var redirect_uri = encodeURIComponent(location.href)
                var forcePopup = true
                var forceSnapShot = true
                var url = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + appid + "&redirect_uri=" + redirect_uri + "&response_type=code&scope=snsapi_userinfo&state=STATE&forcePopup=" + forcePopup + "&forceSnapShot=" + forceSnapShot + "#wechat_redirect"
                location.href = url
                return
            }
            Toast.loading({
                message: '登陆中...',
                forbidClick: true,
            });
            await wxLogin({ code: data.code }).then(response => {
                Toast.clear()
                localStorage.setItem('token', response.token)
            }).catch(err=>{
                console.log(err)
            })
        }
    }
}
export default common 