<template>
  <div></div>
</template>
<script>
import common from "@/tools/common";
export default {
  name: "WechatLoginView",
  data() {
    return {};
  },
  methods: {
    async login() {
      await common.wxLogin(true);
    },
  },
  created() {
    this.login();
  },
};
</script>