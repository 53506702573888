<template>
	<div class="tabs">
		<van-tabs  type="card" color="#1989FA" v-model="current">
			<van-tab  :title="item.title" :name="item.name"  :to="item.to" v-for="item,index in list" :key="index"></van-tab>
		</van-tabs>
	</div>
</template>

<script>
	export default {
		name: "customTabs",
		props: {
			currentName: {
				type: String,
				default: 'increased',
			},
		},
		data() {
			return {
				current:this.currentName,
				list: [
					{
						name:"increased",
						title: "增额终身寿",
						to:"/",
					},
					{
						name:"pension_annuity",
						title: "养老年金",
						to:"/pension_annuity",
					},
					{
						name:"annuity",
						title: "年金",
						to:"/annuity",
					},
				],
			};
		},
		methods: {
			click(item) {
				var url;
				if (item.index == 0) {
					url = "/pages/index/index";
				} else if (item.index == 1) {
					url = "/pages/pensionAnnuity/pensionAnnuity";
				} else if (item.index == 2) {
					url = "/pages/annuity/annuity";
				}

				console.log(url)
			},
		},
	};
</script>

<style lang="scss">
	.tabs {
		margin:20px 0px;
	}
</style>