<template>
	<div id="app">
		<router-view />
	</div>
</template>
<script>
	import { WechatJsSdk,wxLogin } from "@/tools/api"
	import { getParams } from "@/tools/common"
	export default {
		name: "app",
		data() {
			return {

			}
		}, methods: {
			initWechatShare(data){
				window.wx.config(data)
				window.wx.ready(function(){
					// config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
				});
				window.wx.error(function(){
					// config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
					// console.log(res)
				});
			}
		},
		created() {
			// TODO 打包发布需要将此注释打开
			// var ua = navigator.userAgent.toLowerCase(); //判断是否是微信浏览器
			// var isWeixin = ua.indexOf("micromessenger") != -1; //判断是否
			// if (!isWeixin && this.$route.name!="error") {
			// 	this.$router.push({
			// 		name:"error",
			// 		params:{
			// 			msg:"请在微信客户端打开链接",
			// 		}
			// 	})
			// }
			
			

			// WechatJsSdk({url:'http://company.icxac.com/'}).then(data=>{
			// 	this.initWechatShare(data)
			// })
		}
	}
</script>
<style>
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #2c3e50;
		font-size: 14px;
	}
	html,body,#app{
		height: 100%;
	}

	.van-sidebar-item--select::before{
		background-color:#1989FA !important
	}
	.van-stepper__input{
		width:100px !important;
	}
	.van-cascader__title{
		width: 100% !important;
	}
</style>