import axios from './http';
// let baseUrl = 'http://local.jxnewbook.com';
// let baseUrl = 'https://newbook.uins66.com/';
let baseUrl = window.location.protocol+"//"+window.location.hostname

// 微信jssdk参数
export const WechatJsSdk = params => axios.get(baseUrl + '/api/wechat/jsSdkParams', params);
// 获取产品
export const getProduct = params => axios.get(baseUrl + '/api/Product/getProduct', params);
// 获取增额寿对比数据
export const getIncreasedLifespan = params => axios.post(baseUrl + '/api/Increased_Lifespan/getData', params);
// 微信登录
export const wxLogin = async params => axios.get(baseUrl + '/api/wechat/wxLogin', params);